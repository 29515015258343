<template>
  <div
    v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_DATE_BANK.value, path: $route.path}">
    <div class="main-content">
      <div class="loan-amount">
        <div class="field-label">Amount</div>
        <div class="field-value">
          {{ assist.currency.format(layouts.data.approvedAmount) }}
        </div>
      </div>
      <div>
        <wap-select
          v-model="layouts.data.loanDetail.effectiveDate"
          label="Disbursement Date"
          :items="layouts.data.effectiveDateArray"
        ></wap-select>
        <v-textarea
          v-model="layouts.data.loanDetail.bankAccount"
          :readonly="true"
          rows="2"
          auto-grow
          class="mt-4"
          :label="individualTransactionMode === '1' ? 'Disbursement Account' : 'Bank Account'"
        ></v-textarea>
        <v-textarea
          v-if="individualTransactionMode === '1'"
          v-model="layouts.data.loanDetail.bankAccount"
          :readonly="true"
          rows="2"
          auto-grow
          label="Repayment Account"
        ></v-textarea>
      </div>
    </div>
    <div class="footer-wrap">
      <wap-btn
        :block="true"
        width="100%"
        :loading="layouts.loading.continueBtn"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>
  </div>
</template>

<script>
import { Tool, Currency, DataType } from '@/assets/js/util'
import { OnlineApi, InfrastructureApi } from '@/api'
import WapSelect from '@/components/base/WapSelect'
import WapBtn from '@/components/base/WapBtn'

const tool = new Tool()
const currency = new Currency()
export default {
  name: 'loan-details',
  components: { WapSelect, WapBtn },
  data: () => ({
    assist: {
      tool: tool,
      currency: currency,
      dataType: DataType
    },
    layouts: {
      data: {
        approvedAmount: '',
        effectiveDateArray: [],
        loanDetail: {
          effectiveDate: '',
          bankAccount: null,
          bankAccountId: null
        },
        portfolio: {}
      },
      loading: {
        continueBtn: false
      }
    }
  }),
  computed: {
    individualTransactionMode () {
      return this.layouts.data.portfolio.individualTransactionMode
    }
  },
  watch: {
    '$store.getters.getPortfolioJson': {
      handler () {
        this.layouts.data.portfolio = this.$store.getters.getPortfolioJson
      },
      immediate: true,
      deep: true
    },
    'layouts.data.loanDetail.effectiveDate': {
      handler (val) {
        this.$store.commit('setLoan', { ...this.$store.getters.getLoan, effectiveDate: val })
      }
    }
  },
  mounted () {
    this.layouts.data.approvedAmount = this.$store.getters.getApprovedAmount
    this.layouts.data.portfolio = this.$store.getters.getPortfolioJson
    this.listBankData()
    this.getEffectiveDate()
    this.getDefaultDisbursementDate()
  },
  methods: {
    getEffectiveDate () {
      InfrastructureApi.getAvailableDate(
        {
          portfolioId: this.$store.getters.getPortfolioId
        }, (result) => {
          this.layouts.data.effectiveDateArray = []
          for (const item of result) {
            if (tool.isNotEmpty(item)) {
              this.layouts.data.effectiveDateArray.push({ value: item, text: item }) // 须符合select组件的数据结构
            }
          }
        })
    },
    getDefaultDisbursementDate () {
      InfrastructureApi.getDefaultDisbursementDate({ portfolioId: this.$store.getters.getPortfolioId }, (res) => {
        if (tool.isNotEmpty(res)) {
          this.layouts.data.loanDetail.effectiveDate = res
          this.$store.commit('setLoan', { ...this.$store.getters.getLoan, effectiveDate: res })
        }
      }, () => {
      })
    },
    listBankData () {
      OnlineApi.getBankInfo(this.$store.getters.getLoan.loanNo, (result) => {
        if (tool.isNotEmpty(result)) {
          const accountNo = result.accountNo
          const bankName = tool.isNotEmpty(result.bankName) ? result.bankName : ''
          this.layouts.data.loanDetail.bankAccount = bankName + '\n' + '( ******' + accountNo.substring(accountNo.length - 4) + ' )'
        }
      })
    },
    onClickContinue () {
      this.layouts.loading.continueBtn = true
      const loan = this.$store.getters.getLoan
      loan.effectiveDate = this.layouts.data.loanDetail.effectiveDate
      const request = {
        disbursementDate: tool.formatDateWithPattern(loan.effectiveDate, 'MM/dd/yyyy'),
        approvedPrincipal: this.$store.getters.getApprovedAmount
      }
      OnlineApi.savePaymentOption(request, () => {
        this.layouts.loading.continueBtn = false
        this.$router.push('/disbursement/confirm-details')
      }, (err) => {
        this.layouts.loading.continueBtn = false
        this.$store.commit('setPopupInformation', {
          message: err.message
        })
      })
    }
  }
}
</script>

<style scoped lang="sass">
.main-content
  & .loan-amount
    padding: 16px 0

    & .field-label
      opacity: 0.6

    & .field-value
      font-size: 32px
      font-weight: 700
      opacity: 0.8
      color: var(--theme_primary)

  & .action-panel
    margin-top: 16px
</style>
